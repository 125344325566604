// Simple domain indicator that only shows a message box
document.addEventListener('DOMContentLoaded', () => {
  // Get current domain
  const currentDomain = window.location.hostname;
  const isLocalhost = currentDomain.includes('localhost') || 
                      currentDomain.includes('127.0.0.1');
  
  // Create domain message box AFTER domains list
  function createDomainMessage() {
    // Find the footer first
    const footer = document.querySelector('footer');
    if (!footer) return;
    
    // find the smallest paragraph that contains domain links
    // look for the paragraph containing computerdata.computer
    const domainsParagraph = Array.from(footer.querySelectorAll('p')).find(p => 
      p.textContent.includes('computerdata.computer')
    );
    
    if (!domainsParagraph) return;
    
    // Create domain message container
    const domainMessage = document.createElement('div');
    domainMessage.classList.add('domain-message');
    domainMessage.style.cssText = `
      margin-top: 10px;
      padding: 4px 8px;
      font-size: 0.4rem;
      font-family: 'IBM Plex Mono', monospace;
      color: #ffc0cb;
      border-radius: 4px;
      text-align: center;
    `;
    
    // Set message content
    domainMessage.textContent = `current domain: ${currentDomain}`;
    
    // Add after the domains paragraph
    domainsParagraph.parentNode.insertBefore(domainMessage, domainsParagraph.nextSibling);
    
    return domainMessage;
  }
  
  // Create the domain message
  createDomainMessage();
  
  // Add Taiwan-specific message if on Taiwan domain
  if (currentDomain.includes('tw') || (isLocalhost && window.location.search.includes('tw'))) {
    const twMessage = document.createElement('div');
    twMessage.style.cssText = `
      margin: 20px auto;
      padding: 10px;
      max-width: 400px;
      background-color: rgba(0, 0, 0, 0.7);
      border: 1px solid #ffc0cb;
      font-family: 'IBM Plex Mono', monospace;
      font-size: 14px;
      color: #ffc0cb;
      text-align: center;
    `;
    twMessage.innerHTML = '台灣是一個美麗的國家!';
    
    // Add before the footer
    const footer = document.querySelector('footer');
    if (footer) {
      footer.parentNode.insertBefore(twMessage, footer);
    } else {
      // Fallback: add to end of main content
      const mainContent = document.querySelector('.max-w-2xl');
      if (mainContent) {
        mainContent.appendChild(twMessage);
      }
    }
  }

  // For local testing only - add a way to test the Taiwan domain
  if (isLocalhost) {
    const testLink = document.createElement('div');
    testLink.style.cssText = `
      position: fixed;
      top: 10px;
      left: 10px;
      background: rgba(0,0,0,0.8);
      border: 1px solid #ffc0cb;
      padding: 5px;
      font-size: 12px;
      z-index: 9999;
    `;
    
    // Check if we're already in "Taiwan mode" via query param
    if (window.location.search.includes('tw')) {
      testLink.innerHTML = '<a href="?" style="color:#ffc0cb;text-decoration:none;">Exit Taiwan Mode</a>';
    } else {
      testLink.innerHTML = '<a href="?tw=1" style="color:#ffc0cb;text-decoration:none;">Test Taiwan Mode</a>';
    }
    
    document.body.appendChild(testLink);
  }
});